import { DestroyRef, Injectable, inject } from '@angular/core';
import { ApiService } from '../api';
import { switchMap, shareReplay, map, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User, SecurityGroup, UserReference } from './model';
import { Observable, combineLatest } from 'rxjs';
import * as _ from 'lodash';
import { DataStoreService } from '../data-store';
import { AuthService } from '../authentication/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface IUserService {

  /**
   * A stream that emits all users.
   */
  users$: Observable<User[]>;

  /**
   * A stream that emits the current user.
   */
  me$: Observable<User>;

  /**
   * A stream that emits a value to determine whether the current user can edit configurations.
   */
  iHaveRlasConfigEdit$: Observable<boolean>;

  /**
   * A stream that emits a value to determine whether the current user can access default answers.
   */
  iHaveRlasDefaultAnswersAccess$: Observable<boolean>;

  /**
   * A stream that emits a value to determine whether the current user can edit default answers.
   */
  iHaveRlasDefaultAnswersEdit$: Observable<boolean>;

  /**
   * A stream that emits a value to determine whether the current user can edit the global authority.
   */
  iHaveRlasGlobalAuthorityEdit$: Observable<boolean>;

  /**
   * A pipeline that emits a list of ace users.
   */
  aceUsers$: Observable<UserReference[]>;

  /**
   * A pipeline that emits a combined list of harts and ace users.
   */
  hartsAndAceUsers$: Observable<UserReference[]>;
}

/**
 * A service to handle HARTs User API calls.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService implements IUserService {

  /**
   * The {@link AuthService} instance to use.
   */
  public readonly authService = inject(AuthService);
  
  /**
   * The {@link ApiService} instance to use.
   */
  public readonly apiService = inject(ApiService);
  
  /**
   * The {@link DataStoreService} instance to use.
   */
  public readonly dataStoreService = inject(DataStoreService);
  
  /**
   * The {@link HttpClient} instance to use.
   */
  public readonly httpClient = inject(HttpClient);
  
  /**
   * The {@link DestroyRef} instance to use.
   */
  public readonly destroyRef = inject(DestroyRef);

  /**
   * @inheritdoc
   */
  public readonly users$ = this.apiService.api$.pipe(
    switchMap(x => this.httpClient.get<User[]>(x.UsersController)),
    map(users => _.sortBy(users, u => u.name)),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * @inheritdoc
   */
  public readonly me$ = combineLatest([this.apiService.api$, this.authService.account$]).pipe(
    filter(([urls, account]) => account !== null),
    switchMap(([urls, account]) => this.dataStoreService.loadObjectNoCache<User>(urls['UsersController.GetCurrentUser'])),
    map(user => user.data),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * @inheritdoc
   */
  public readonly iHaveRlasConfigAccess$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasConfigAccess)),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * @inheritdoc
   */
  public readonly iHaveRlasConfigEdit$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasConfigEdit)),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * @inheritdoc
   */
  public readonly iHaveRlasDefaultAnswersAccess$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasDefaultAnswersAccess)),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * @inheritdoc
   */
  public readonly iHaveRlasDefaultAnswersEdit$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasDefaultAnswersEdit)),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * @inheritdoc
   */
  public readonly iHaveRlasGlobalAuthorityEdit$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasGlobalAuthorityEdit)),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * @inheritdoc
   */
  public readonly aceUsers$ = this.apiService.api$.pipe(
    switchMap(x => {
      const url = new URL(x['UsersController.GetGroups']);
      url.searchParams.append('groupName', 'aceUsers');
      return this.dataStoreService.loadObject<UserReference[]>(url.href);
    }),
    map(users => users.data),
    map(users => _.sortBy(users, u => u.name)),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * @inheritdoc
   */
  public readonly hartsAndAceUsers$: Observable<UserReference[]> = combineLatest([this.users$, this.aceUsers$]).pipe(
    map(([hartsUsers, aceUsers]) => [...hartsUsers, ...aceUsers]),
    map(users => users.sort((a, b) => a.name.localeCompare(b.name))),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 })
  );
}
