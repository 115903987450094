import { DestroyRef, Injectable, inject } from '@angular/core';
import { ApiService } from '../api';
import { switchMap, shareReplay, map } from 'rxjs/operators';
import { SearchClerk } from './model';
import { Observable, combineLatest } from 'rxjs';
import { DataStoreService, DataStoreObject } from '../data-store';
import { RlasApiModule } from './rlas-api.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * A service to fetch Search Clerk data.
 */
export interface ISearchClerkService {

  /**
   * A stream of current Search Clerks.
   */
  searchClerks$: Observable<SearchClerk[]>;
}

/**
 * A service to fetch Search Clerk data.
 */
@Injectable({
  providedIn: RlasApiModule
})
export class SearchClerkService implements ISearchClerkService {

  /**
   * The {@link ApiService} instance to use.
   */
  public readonly apiService = inject(ApiService);

  /**
   * The {@link DataStoreService} instance to use.
   */
  public readonly dataStoreService = inject(DataStoreService);
  
  /**
   * The {@link DestroyRef} instance to use.
   */
  public readonly destroyRef = inject(DestroyRef);
  
  /**
   * @inheritdoc
   */
  public readonly searchClerks$ = this.apiService.api$.pipe(
    switchMap(x => this.dataStoreService.loadObject<DataStoreObject<SearchClerk>[]>(x.SearchClerkController)),
    switchMap(x => combineLatest(x.data.map(m =>
      this.dataStoreService.loadObject<SearchClerk>(m.href)))),
    map(x => x.map(m => m.data).sort((clerk, otherclerk) => (clerk.name > otherclerk.name ? 1 : -1))),
    takeUntilDestroyed(this.destroyRef),
    shareReplay({ refCount: false, bufferSize: 1 }),
  );
}
